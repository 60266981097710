.sidebar {
  flex: 1 1;
  border-right: 0.5px solid #f1f1f1;
  max-height: 100vh;
  background-color: white;
  min-width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 99;
  transition-duration: 0.5s;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 0.5rem;
    padding: 15px 0 5px;

    .logo {
      width: 100px;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid #f1f1f1;
  }


  .dropdown {
    .dropdown-btn {
      display: flex;
      justify-content: space-between;

      .dropdown-name {
        display: flex;
        align-items: center;
      }

      svg {
        color: #888888;
      }
    }
  }

  .center {
    // padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .dropdown-items {
        margin: 5px 10px;
        border-radius: 5px;
        overflow: hidden;
      }

      .title {
        font-size: 12px;
        font-weight: 600;
        color: #212529;
        margin: 10px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 20px;
        cursor: pointer;
        position: relative;

        &:hover,
        &:focus {
          background-color: #ece8ff;

          span,
          svg {
            color: #6439ff;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            background-color: #6439ff;
            border-radius: 0 3px 3px 0px;
          }
        }

        .icon {
          font-size: 18px;
          color: #7451f8;
        }

        span {
          font-size: 15px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }
      }

      .active {
        >li {
          background-color: #ece8ff;

          span,
          svg {
            color: #6439ff;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            background-color: #6439ff;
            border-radius: 0 3px 3px 0px;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }

      &:nth-child(2) {
        background-color: #333;
      }

      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}