.invoice{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.top {
    // padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // background-color: #F0F4F8;
    .left {
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      position: relative;
      background-color: #fff;
      border-radius: 15px;
      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 12px;
        color: #7451f8;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;

        .itemImg {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }

        .details {
          .itemTitle {
            margin-bottom: 10px;
            color: #555;
          }

          .detailItem {
            margin-bottom: 10px;
            font-size: 14px;

            .itemKey {
              font-weight: bold;
              text-transform: capitalize;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
    }

    .right {
      flex: 2;
    }
  }