.home {

  .widgets,
  .charts {
    display: flex;
    gap: 20px;
  }

  .charts {
    padding: 5px 20px;
  }

  .dates {
    padding: 20px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
  }

  .listContainer {
    padding-top: 25px;

    .listRow {
      margin-bottom: 40px;

      .listTitle {
        color: #3E4D60;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
}
.alarm {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  // background-color: #ffffff;
  // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
