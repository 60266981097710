.forgotPass {
    min-height: 100vh;
    margin-top: 0 !important;
    .right {
        background-color: #fff;
        padding: 0 !important;
    }

    .left {
        position: relative;
        background-image: url(../../images/login-banner.jpg);
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
        padding: 50px !important;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(35, 29, 121, 0.6);
            z-index: -1;
        }
        .main-heading{
            font-size: 70px;
            line-height: 90px;
            font-weight: 600;
            color: #fff;
            margin-bottom: 30px;
            text-align: start;
        }
        .desc{
            color: #fff;
        }
    }
}