.tabs {
  border-radius: 15px;
  overflow-x: auto;
  background-color: #ffffff;

  .MuiAppBar-colorDefault {
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    border-bottom: 1px solid rgb(231, 228, 228);
    box-shadow: none !important;
  }

  .MuiTypography-body1 {
    .extra-buttons {
      padding: 15px;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 15px;
    }

    .MuiBox-root {
      padding: 0;

      .datatable {
        border-radius: 0;
        box-shadow: 0;
        margin: 0;
      }
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    text-transform: capitalize;
  }

  .MuiBox-root-6 {
    padding: 0;

    .datatable {
      margin-left: 0;
      margin-right: 0;
    }
  }

}

.tool-bar {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  border-radius: 15px;
  align-items: center;

  .color-plate {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 5px;
    border-radius: 3px;
    gap: 3px;

    .color-palte-item {
      background-color: rgba(255, 255, 255, 0.8);
      height: 20px;
      width: 20px;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      color: #ffffff;
      &:nth-child(1) {
        background-color: #ff0000;
      }
    
      &:nth-child(2) {
        background-color: #ffa500;
      }
    
      &:nth-child(3) {
        background-color: #6439ff;
      }
    
      &:nth-child(4) {
        background-color: #03C04A;
      }
    
      &:nth-child(5) {
        background-color: black;
      }
    
      &:nth-child(6) {
        background-color: #5bc0de;
      }
    
      &:nth-child(7) {
        background-color: #FFCF9D;
      }
    
      &:nth-child(8) {
        background-color: #662549;
      }
    
      &:nth-child(9) {
        background-color: #FFB6D9;
      }
    }
  }
 
  .btn-group {
    display: flex;
    gap: 15px;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;

    input[type=file] {
      width: 195px;
    }
  }
}

.color-box {
  // opacity: 0.7;
  color: #fff;
  .red { background-color: red; }
    .yellow { background-color: #ffa500; }
    .purple { background-color: #6439ff; }
    .green { background-color: #03C04A;}
    .black { background-color: black; }
    .skyblue {  background-color: #5bc0de ; }
    .sceen { background-color: #FFCF9D; }
    .darkpurple { background-color: #662549; }
    .pink { background-color: #FFB6D9 ; }
    .white { color: #000;}
}

.hw-12 {
  height: 12px !important;
  width: 12px !important;
}

@media only screen and (min-width: 1100px) {
  .tabs {
    header {
      .MuiTabs-root {
        width: fit-content !important;
        margin: 0 auto;
      }
    }
  }
}