.navbar {
  height: 60px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  background-color: #ffffff;
  margin-left: 250px;
  width: calc(100% - 250px);
  transition-duration: 0.5s;
  position: fixed;
  z-index: 100;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .showHideBtn {
      cursor: pointer;
      background-color: #6439ff;
      color: white;
      border-radius: 5px;
      padding: 3px;

      &:hover {}
    }

    .items {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .user-name{
          font-weight: 700;
        }

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }

  }
}

.logout-select-field {
  .MuiPopover-paper {
    top: 50px !important;
    right: 35px !important;
    left: initial !important;
    width: 200px !important;

    a {
      font-weight: 700;
      width: 100%;
      display: flex;
      gap: 5px;
      padding: 6px 8px;
      color: #6439ff;
    }
  }
}